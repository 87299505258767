import React from 'react'
import { Route } from 'react-router-dom'
import Main from './Main'
import './App.css'

export default () => (
  <div className='app'>
    <Route path='/:mode' component={Main} />
    <Route component={Main} />
  </div>
)
