import React from 'react'
import Generator from './Generator'
import { Spring, config } from 'react-spring/renderprops'

class Main extends React.Component {
  constructor(props) {
    super(props)
    let mode = this.props.match.params ? this.props.match.params.mode:null
    let subdomain = window.location.host.match(/(([^.]+)?\.)./)
    subdomain = subdomain ? subdomain[2]:null
    mode = mode||subdomain
    mode = Generator.has(mode) ? mode:'corporate'
    this.generator = new Generator(mode)
    this.state = { current:this.generator.generate() }
  }

  generate = () => this.setState({ current:this.generator.generate() })

  render() {
    const { current } = this.state
    return (
      <div className='main'>
        { current && <span className='phrase'>{ current }</span> }
        <div onClick={this.generate} className='button'>Generate</div>
      </div>
    )
  }
}

export default Main
