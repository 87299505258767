import Library from './lib'

class Generator {
  constructor(mode) {
    this.valid = Library[mode] ? true:false
    this.adverbs = [ ...Library[mode].adverbs ]
    this.verbs = [ ...Library[mode].verbs ]
    this.adjectives = [ ...Library[mode].adjectives ]
    this.nouns = [ ...Library[mode].nouns ]
  }
  static has = lib => Library[lib] ? true:false
  get adverb() {
    let adverbs = this.randomize(this.adverbs)
    let adverb = adverbs.shift()
    return adverb.toLowerCase()
  }
  get verb() {
    let verbs = this.randomize(this.verbs)
    let verb = verbs.shift()
    return verb.toLowerCase()
  }
  get adjective() {
    let adjectives = this.randomize(this.adjectives)
    let adjective = adjectives.shift()
    return adjective.toLowerCase()
  }
  get noun() {
    let nouns = this.randomize(this.nouns)
    let noun = nouns.shift()
    return noun.toLowerCase()
  }
  randomize = arr => {
    arr = [ ...arr ]
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = arr[i]
      arr[i] = arr[j]
      arr[j] = temp
    }
    return arr
  }
  generate = () => {
    if (!this.valid) return 'Invalid Generator!'
    let { adverb, verb, adjective, noun } = this
    return `${adverb[0].toUpperCase()}${adverb.slice(1)} ${verb} ${adjective} ${noun}`
  }
}

export default Generator
